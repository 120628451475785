const [ d, w, mq ] = [ document, window, window.matchMedia("(min-width:900px)") ];

const domReady = () => {
  new serviceBLOCK;
};

class serviceBLOCK {
  constructor() {
    this.tagChange();
    this.textFloat();
  }

  tagChange () {
    const article2 = d.body.classList.contains('is--article-12');
    if (article2){
      function tagChange(){
        $('.sale_btn').on('click', function(){
          $('.buy_btn').removeClass('current');
          $('.buy_wrap').removeClass('current');
          $('.sale_btn').addClass('current');
          $('.sale_wrap').addClass('current');
        });
        $('.buy_btn').on('click', function(){
          $('.sale_btn').removeClass('current');
          $('.sale_wrap').removeClass('current');
          $('.buy_btn').addClass('current');
          $('.buy_wrap').addClass('current');
        });
      }
      setTimeout(() => {
        tagChange();
      }, 500);
    }
  }

  textFloat() {
    const article4 = d.body.classList.contains('is--article-14');
    if(article4){
      if(mq.matches){
        function floatHeight(){
          $('.space').css('float','none');
          let height = $('.float_text').outerHeight();
          $('.space').outerHeight( height - 140 ).css('float','right');
        }
        setTimeout(() => {
          floatHeight();
        }, 500);
      }
    }
  }
}

export default function RECRUIT () {
  $(w).on('load', domReady);
}