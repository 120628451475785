import GET_ARTICLES from "../class/getArticles";
import Swiper from "swiper/bundle";

const [ w, d, mq ] = [window, document, window.matchMedia( "(max-width: 900px)" ) ];
const domReady = TPL => {
  new BLOCKS;
};

class BLOCKS {
  constructor() {
    this.mainvisual();
    this.news();
    this.case();
  }

  mainvisual () {
    let Mv = document.querySelector('.mainvisual');
    window.addEventListener('load', function() {
      Mv.classList.add('loaded');
    });
  }

  news () {
    const getArticle = new GET_ARTICLES('.block-news .news_list');
    getArticle.makeItem = (item,content) => `
    <li>
      <a${(item.is_new)? ' class="is-new"': ''} href="${item.href}">
        <p class="info"><span class="date">${item.date}</span><span class="cate">${item.category.name}</span></p>
        <p class="ttl">${item.title}</p>
      </a>
    </li>`;
    // getArticle.makeItem = (item) => console.log(item);
    getArticle.render();

    // カテゴリー
    const getContentData = Promise.all([
      getArticle.fetchArticles({
        content: 'news',
        post_per_page: 1,
        category_in: 0
      }),
      getArticle.fetchCategories('news'),
    ]).then( res => Promise.all(res.map(r=>r.json())) );

    let categoryContainer = d.querySelector('.block-news .cate_list[data-plugin="getCategories"]');
    getContentData.then( ([noCatArticles,cats]) => {
      categoryContainer.innerHTML = '';
      if( noCatArticles && noCatArticles.length > 0){
        cats.push({id:'0',name:'その他',href:`/news/?mode=list&cat=0`});
      }
      cats.forEach( item => {
        let itemHtml = `<li><a href="${item.href}">${item.name}</a></li>`;
        categoryContainer.insertAdjacentHTML('beforeend', itemHtml);
      });
    });
  }

  case () {
    const getArticles = this.fetchArticles({
      content: 'case',
      post_per_page: 10
    });
    const getCategories = this.fetchCategories('case');
    const appendSlide = (item,slider) => {
      slider.appendSlide([`
        <div class="swiper-slide">
          <a href="${item.href}">
            <figure>
              <img src="${item.thumb}" alt="${item.title}">
              ${(item.category.name)? `<figcaption>${item.category.name}</figcaption>`: ''}
            </figure>
            <div class="text_wrap">
              <p class="ttl">${item.title}</p>
            </div>
          </a>
        </div>
      `]);
    }
    const slider = new Swiper('.case_slide', {
      grabCursor: true,
      speed: 400,
      slidesPerView: 1.2,
      spaceBetween: 30,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.case-next',
        prevEl: '.case-prev',
      },
      pagination: {
        el: '.case-pagination',
        type: 'progressbar',
        clickable: true,
      },
      breakpoints: {
        500: {
          slidesPerView: 'auto',
          spaceBetween: 38,
        }
      },
    });
    slider.removeAllSlides();
    Promise.all([getArticles,getCategories]).then( ([articles,cats]) => {
      d.querySelector('.case_slide .swiper-wrapper').innerHTML = '';
      articles.map( article => {
        article.category = cats.find( v => v.id == article.category ) || {id: '0', href: '/case/?mode=list&cat=0', name: 'その他', turn: 99};
        appendSlide(article,slider);
      });
      slider.slideToLoop(0);
    });
  }

  fetchCategories (content) {
    return fetch(`/assets/api/getCategories/?content=${content}`)
      .then(r => r.json())
      .catch(e => { throw new Error(e)});
  }

  fetchArticles (opt){
    return fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`)
    .then(r => r.json())
    .catch(e => { throw new Error(e)});
  }
}

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}