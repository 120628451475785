import RWD002 from "../class/rwd002";
import customDrawerNav from "./drawerNav";
import customFormConfirm from "./formConfirm";
import customSubNav from "./subNav";
import LazyDisp from "../class/lazydisp";

const [d] = [document];

export default class CUSTOMIZE extends RWD002 {

  constructor(opt) {
    opt = Object.assign({
      // fixNavを指定すると、スクロール時に固定するクラスを付与する
      // fixNav : '[data-role="fixNav"]',
    },opt);

    // 上位クラスのコンストラクタ呼び出し
    super(opt);

    // カスタム用drawerNavのインスタンス化
    this.drawerNav = new customDrawerNav(this.opt.drawerNav);

    // カスタム用サブナビ登録クラスのインスタンス化
    this.Subnav = new customSubNav(this.opt);

    // コメントフォーム用確認画面モーダルのインスタンス化
    this.formConfirm = new customFormConfirm(this.opt.formConfirm);

    this.customInit();
    this.headYear();
    this.footerSlide();
    this.pageTop();
  }

  customInit() {

    // イベント一覧
    d.addEventListener('rwd002.beforeInit', () => false );
    d.addEventListener('rwd002.beforeDomready', () => false );
    d.addEventListener('rwd002.beforeWindowload', () => false );
    d.addEventListener('rwd002.zip2addr', () => false );
    d.addEventListener('drawerNav.beforeInit', () => false );
    d.addEventListener('collapseNav.beforeInit', () => false );
    d.addEventListener('Scroll.beforeInit', () => false );
    d.addEventListener('Scroll.scrollUp', () => false );
    d.addEventListener('Scroll.scrollDown', () => false );
    d.addEventListener('Lazydisp.beforeInit', () => false );

    // テンプレートインスタンスのプロミス
    this.promises.init.then( () => false );
    this.promises.domReady.then( () => false );
    this.promises.windowLoad.then( () => false );

    // テンプレートインスタンスの全非同期メソッドの完了時に実行
    this.promiseAll().then( () => false );

    // サブナビ登録サンプル
    d.addEventListener('rwd002.beforeDomready', () => {
      this.customSubnav();
    });

    $(() => {
      // htmlデータ読み込み
      $('[data-load-html]').loadHtml();

      // カレントメニュー
      $('[data-plugin=currentMenu]').currentMenu({mode:0,default:999});

      Promise.all([window.load['loadHtml']]).then(() => this.hashscroll());
      Promise.all([window.load['loadHtml']]).then(() => new LazyDisp('[data-lazydisp]'));
    });
  }

  // サブメニュー追加サンプル(beforeDomreadyに登録して使用)
  customSubnav () {
    /**
     * サブナビ追加メソッド(グローバルナビとフッター)
     * ヘッダー・フッター内の[data-key]属性値をもつ要素すべてに対して、サブナビを追加する
     * 第一引数にセレクタを指定(rwd002.opt.gnav / rwd002.opt.fnav )
     * 第一引数にコンテンツ番号を指定(li[data-key])
     * アイテムの出力HTMLは"subnavItemTemplate"によって出力される
     * 第二引数の個別アイテムにHTMLを指定すると、デフォルトのHTMLを上書きする
     * 親要素liに[data-collapse="true"]を指定すると、サブナビを開閉するボタンを追加する
     */

    // header gnav用サブナビ追加
    this.Subnav.add(this.opt.gnav, 'content2', [
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/service/" class="${this.Subnav.opt.subnavClass}-item__link">
            <figure class="icon icon1 pc"><img src="/assets/img/common/business01.svg"></figure>
            <p class="text"><span>不動産</span><span>コンサルティング</span></p>
            <figure class="arrow pc"><img src="/assets/img/common/arrow_gr.svg"></figure>
          </a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/service/?mode=detail&article=12" class="${this.Subnav.opt.subnavClass}-item__link">
            <figure class="icon icon2 pc"><img src="/assets/img/common/business02.svg"></figure>
            <p class="text"><span>売却・購入の</span><span>コンシェルジュ</span></p>
            <figure class="arrow pc"><img src="/assets/img/common/arrow_gr.svg"></figure>
          </a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/service/?mode=detail&article=13" class="${this.Subnav.opt.subnavClass}-item__link">
            <figure class="icon icon3 pc"><img src="/assets/img/common/business03.svg"></figure>
            <p class="text"><span>有効活用の</span><span>コンシェルジュ</span></p>
            <figure class="arrow pc"><img src="/assets/img/common/arrow_gr.svg"></figure>
          </a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/service/?mode=detail&article=14" class="${this.Subnav.opt.subnavClass}-item__link">
            <figure class="icon icon4 pc"><img src="/assets/img/common/business04.svg"></figure>
            <p class="text"><span>ランドセットによる</span><span>賃貸経営</span></p>
            <figure class="arrow pc"><img src="/assets/img/common/arrow_gr.svg"></figure>
          </a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/service/?mode=detail&article=15" class="${this.Subnav.opt.subnavClass}-item__link">
            <figure class="icon icon5 pc"><img src="/assets/img/common/business05.svg"></figure>
            <p class="text"><span>終活のすすめ</span></p>
            <figure class="arrow pc"><img src="/assets/img/common/arrow_gr.svg"></figure>
          </a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/service/?mode=detail&article=16" class="${this.Subnav.opt.subnavClass}-item__link">
            <figure class="icon icon6 pc"><img src="/assets/img/common/business06.svg"></figure>
            <p class="text"><span>医療・介護支援</span><span>サービス</span></p>
            <figure class="arrow pc"><img src="/assets/img/common/arrow_gr.svg"></figure>
          </a>
        </li>`
      },
    ]);

    // footer nav用サブナビ追加
    this.Subnav.add(this.opt.fnav, 'content1', [
      { label: 'サブメニュー01', href: '#'},
      { label: 'サブメニュー02', href: '#'},
      { label: 'サブメニュー03', href: '#'},
    ]);
  }

  headYear () {
    let now=new Date()

    const foundingYear = '2006';
    const foundingMonth = '7';
    const foundingDate = '19';
    const nowYear = now.getFullYear();
    const nowMonth = now.getMonth() + 1;
    const nowDate = now.getDate();
    const countYear = nowYear - foundingYear;
    
    function yearRewrite() {
      if(nowMonth > foundingMonth){
        document.getElementById('countYear').innerHTML = countYear;
      } else if(nowMonth == foundingMonth && nowDate >= foundingDate){
        document.getElementById('countYear').innerHTML = countYear;
      } else {
        document.getElementById('countYear').innerHTML = countYear - 1;
      }
    }
    
    setTimeout(() => {
      yearRewrite();
    },500);
  }

  footerSlide () {
    $(window).on('load', function(){
      $('footer .slide_trigger').on('click', function(){
        $(this).toggleClass('open');
        $(this).next('.sub_list').slideToggle();
      });
    });
  }

  pageTop () {
    $(window).on('load', function () {
      let winH = $(window).height();
      $(window).on('scroll', function () {
        let t = $(this).scrollTop();
        let stopPt = $('footer .foot_content').offset().top;
        let scrollPt = t + winH - 260;
        if (t > winH) {
          $('.pagetop').addClass('active');
        } else {
          $('.pagetop').removeClass('active');
        }
        if (scrollPt > stopPt){
          $('.pagetop').addClass('stop');
        } else {
          $('.pagetop').removeClass('stop');
        }
      });
    });
  }

  loading() {
    d.documentElement.classList.add( this.opt.loadingClass );
    d.documentElement.classList.add( 'is-pageload-available' );
    d.addEventListener('DOMContentLoaded', () => {
      d.documentElement.classList.remove( this.opt.loadingClass );
      d.documentElement.style.opacity = '';
    });
  }
  
}

window.WebFontConfig = {
  google: {
    families: ['Barlow:400,500,600', 'Roboto:400,500']
  },
  active: function () {
    sessionStorage.fonts = true;
  }
};

(function () {
  var wf = document.createElement('script');
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
  wf.type = 'text/javascript';
  wf.async = 'true';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wf, s);
})();